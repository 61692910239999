import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { Container, Row, Col } from "reactstrap";
import IconsOppo from "../components/icons/investors/iconOpportunities";
import SmallERotate from "../components/icons/animation/eRotation-small";
import InvestorStack from "../components/icons/animation/InvestorStack";
import HowWeWorkV2 from "../components/investors/hwwAccordionV2";
import SideArrow from "../components/icons/arrows/SideArrow";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

const Investors = ({ data }) => {
  // eslint-disable-next-line
  let parallax;
  return (
    <Parallax ref={ref => (parallax = ref)}>
      <Layout>
        <SEO
          title={data.wpgraphql.page.title}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
        <article>
          <header className="header--fullwidth header--darkbg">
            {data.wpgraphql.page.featuredImage != null && (
              <Img
                className="header__image"
                fluid={
                  data.wpgraphql.page.featuredImage.imageFile.childImageSharp
                    .fluid
                }
              />
            )}
            <Container>
              <Row>
                <Col xs={{ offset: 1 }}>
                  <h1 className="heading1 header__message">
                    {/* {data.wpgraphql.page.acf_investor.heroHead} */}
                    <strong>PRESERVATION FIRST,</strong>
                    <br />
                    APPRECIATION SECOND.
                  </h1>
                </Col>
              </Row>
            </Container>
          </header>
          <Container>
            <section className="section__intro">
              <Row>
                <Col
                  xs={{ size: 12, offset: 1 }}
                  md={{ size: 7, offset: 5 }}
                  lg={{ size: 6 }}
                >
                  <h2 className="heading2">
                    {data.wpgraphql.page.acf_investor.heroSubhead}
                  </h2>
                  <p className="body-copy">
                    {data.wpgraphql.page.acf_investor.heroText}
                  </p>
                </Col>
              </Row>
            </section>
            <section className="section__investment-opportunities">
              <Row className="no-gutters">
                <Col
                  xs={{ size: 12, offset: 1 }}
                  sm={{ offset: 0 }}
                  md={{ size: 4 }}
                  lg={{ size: 5 }}
                >
                  <Row className="no-gutters">
                    <Col sm={{ offset: 1 }} lg={{ size: 10 }}>
                      <h2 className="heading2">
                        {data.wpgraphql.page.acf_investor.section1.head}
                      </h2>
                      <p className="body-copy">
                        {data.wpgraphql.page.acf_investor.section1.text}
                      </p>
                    </Col>
                  </Row>
                </Col>

                <Col md={{ size: 9 }}>
                  <Row className="no-gutters">
                    <Col
                      sm={{ size: 8, offset: 3 }}
                      md={{ size: 12, offset: 1 }}
                      lg={{ offset: 0 }}
                    >
                      <IconsOppo />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>
          </Container>
          <ParallaxLayer
            offset={-0.2}
            speed={0.15}
            className="parallax__arrow-container d-none d-md-block"
          >
            <SideArrow className="parallax__arrow-right" />
          </ParallaxLayer>
          <section className="section__how-we-work">
            <Container>
              <Row>
                <Col
                  sm={{ size: 10, offset: 2 }}
                  md={{ size: 4, offset: 0 }}
                  lg={{ size: 4, offset: 1 }}
                >
                  <h2 className="heading2">
                    {data.wpgraphql.page.acf_investor.section2.head}
                  </h2>
                  <p className="body-copy">
                    {data.wpgraphql.page.acf_investor.section2.text}
                  </p>
                </Col>
                <Col
                  md={{ size: 5 }}
                  lg={{ size: 4 }}
                  className="icon__ideal-e d-none d-md-block"
                ><SmallERotate className="w-75" />
                </Col>
                <Col
                  sm={{ size: 10, offset: 2 }}
                  md={{ size: 5, offset: 0 }}
                  lg={{ size: 4 }}
                >
                  <HowWeWorkV2 />
                </Col>
              </Row>
            </Container>
          </section>
          <Container>
            <section className="section__customized-funds">
              <Row>
                <Col md={{ size: 8 }} lg={{ size: 6, offset: 1 }}>
                  <div>
                    <h2 className="heading2">
                      {data.wpgraphql.page.acf_investor.section3.head}
                    </h2>
                    <p className="body-copy">
                      {data.wpgraphql.page.acf_investor.section3.text}
                    </p>
                    <p className="body-copy">We handle all in-house:</p>
                    <ul className="bulleted-list">
                      <Row>
                        <Col sm={{ size: 6 }} md={{ size: 6 }}>
                          <li>Asset management</li>
                          <li>Reporting</li>
                          <li>Tax information</li>
                          <li>Construction management</li>
                        </Col>
                        <Col sm={{ size: 6 }} md={{ size: 6, offset: 1 }}>
                          <li>Fund Strategy</li>
                          <li>Underwriting</li>
                          <li>Acquisitions</li>
                          <li>Capital Stack Optimization</li>
                        </Col>
                      </Row>
                      {/* {data.wpgraphql.page.acf_investor.section3.bullets.map(
                  bullets => (
                    <li>{bullets.bullet}</li>
                  )
                )} */}
                    </ul>
                  </div>
                </Col>
                <Col md={{ size: 6 }}>
                  <InvestorStack />
                </Col>
              </Row>
            </section>
          </Container>
        </article>
      </Layout>
    </Parallax>
  );
};

export default Investors;

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cGFnZToxMg==") {
        title
        acf_page_meta {
          metadescription
        }
        acf_investor {
          heroHead
          heroSubhead
          heroText
          section1 {
            head
            text
          }
          section2 {
            head
            text
            block1Title
            block1Text
            block2Title
            block2Text
            block3Title
            block3Text
          }
          section3 {
            head
            text
            bulletHead
            bullets {
              bullet
            }
          }
        }
        featuredImage {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
