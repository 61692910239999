import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { animated, useTrail, config } from 'react-spring';
import { Row, Col } from "reactstrap";
import { Waypoint } from "react-waypoint"
import SVGPreservationIcon from "./PreservationIcon";
import SVGHistoryIcon from "./HistoryIcon";
import SVGAcronymIcon from "./AcronymIcon";
import TaxPrepIcon from "./TaxPrepIcon";


const items = [<SVGPreservationIcon/>, <SVGHistoryIcon/>, <SVGAcronymIcon/>, <TaxPrepIcon/>];

const IconsOppo = () => {
  const data = useStaticQuery(graphql`
    query IconOQuery {
      wpgraphql {
         page(id: "cGFnZToxMg==") {
           acf_icons_opportunity {
             iconsOpportunity {
               headerText

             }
         }
        }
      }
    }
  `)
  
  const icons = data.wpgraphql.page.acf_icons_opportunity.iconsOpportunity;
  
  const [on, toggle] = useState(false);
  const trail = useTrail(icons.length, {
    from: { opacity: 0, transform: 'translate(0px,100px)' },
    to: { opacity: on ? 1 : 0, transform: on ? 'translate(0px,0px)' : 'translate(0px,100px)'},
    config: config.stiff
  });


  return (
    <>
      <Waypoint
        bottomOffset='25%'
        // bottom of window
        onEnter={() => {
          if (!on) toggle(true)
        }}
      />
      <ul className="icon-list">
      {trail.map(({ ...animation }, index) => (
        <animated.li className="icon-list__item" style={{ ...animation }} key={index}>
        <Row>
          <Col md={{ size: 3 }} lg={{ size: 3 }}>
            <span className="icon-list__icon">
            {items[index]}
            </span>
            </Col>
            <Col md={{ size: 11 }} lg={{ size: 6 }}>
              <h4 className="heading4">
                {icons[index].headerText}
                </h4>
              </Col>
            </Row>
          </animated.li>
      ))}
      </ul>
      </>
  )
}

export default IconsOppo


