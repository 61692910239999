import React from "react";

const SvgTaxPrepIcon = props => (
  <svg
    id="TaxPrep_icon_svg__Layer_1"
    x={0}
    y={0}
    viewBox="0 0 210 209"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".TaxPrep_icon_svg__st0{fill:#77bde2}"}</style>
    <g id="TaxPrep_icon_svg__Group_136" transform="translate(-8.593)">
      <path
        id="TaxPrep_icon_svg__Path_178"
        className="TaxPrep_icon_svg__st0"
        d="M164.9 99.8V39.3c0-2.7-2.2-5-5-5H57.5c-2.7 0-5 2.2-5 5v131.4c0 2.7 2.2 5 5 5h71.7c21 10.1 46.1 1.3 56.2-19.7 10.1-21 1.3-46.1-19.7-56.2-4.9-2.4-10.2-3.7-15.7-4.1l-9.9.6c-.5.1-.9.2-1.4.3-22.7 4.8-37.3 27.1-32.5 49.8 1.5 7.2 4.9 13.9 9.8 19.4H62.4V44.3H155v54.5m-7.5 71.1c-17.8 0-32.2-14.4-32.2-32.2 0-17.8 14.4-32.2 32.2-32.2s32.2 14.4 32.2 32.2c0 17.8-14.4 32.2-32.2 32.2z"
      />
      <path
        id="TaxPrep_icon_svg__Path_182"
        className="TaxPrep_icon_svg__st0"
        d="M83.8 70.5H134c2.7 0 5-2.2 5-5 0-2.7-2.2-5-5-5H83.8c-2.7 0-5 2.2-5 5s2.2 5 5 5z"
      />
      <path
        id="TaxPrep_icon_svg__Path_183"
        className="TaxPrep_icon_svg__st0"
        d="M83.8 96.8h35c2.7 0 4.9-2.2 4.9-5 0-2.7-2.2-4.9-4.9-4.9h-35c-2.7 0-4.9 2.2-4.9 5-.1 2.7 2.1 4.9 4.9 4.9z"
      />
      <path
        id="TaxPrep_icon_svg__Path_184"
        className="TaxPrep_icon_svg__st0"
        d="M92.5 113.2h-8.8c-2.7 0-4.9 2.2-4.9 5 0 2.7 2.2 4.9 4.9 4.9h8.8c2.7 0 4.9-2.2 4.9-5 .1-2.7-2.1-4.9-4.9-4.9z"
      />
      <path
        id="TaxPrep_icon_svg__Path_186"
        className="TaxPrep_icon_svg__st0"
        d="M111 136c1-17.5 12.9-23.5 20.2-29.3s26.2-1.9 26.2-1.9l8.3-5.1c-21-10.1-46.1-1.3-56.2 19.7-2.7 5.7-4.2 11.9-4.2 18.3l5.7-1.7z"
      />
      <g
        id="TaxPrep_icon_svg__Group_138"
        transform="translate(13.993 -245.141)"
      >
        <g
          id="TaxPrep_icon_svg__Group_137"
          transform="translate(53.983 303.237)"
        >
          <path
            id="TaxPrep_icon_svg__Path_187"
            className="TaxPrep_icon_svg__st0"
            d="M79.4 76c-2.7 0-4.9-1.8-4.9-4.1s2.2-4.1 4.9-4.1 4.9 1.8 4.9 4.1 1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1c-.1-5.5-3.8-10.2-9-11.7 0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1c-5.3 1.5-8.9 6.2-9 11.7 0 6.8 5.9 12.3 13.1 12.3 2.7 0 4.9 1.8 4.9 4.1s-2.2 4.1-4.9 4.1-4.9-1.8-4.9-4.1-1.8-4.1-4.1-4.1-4.1 1.8-4.1 4.1c.1 5.5 3.8 10.2 9 11.7v.2c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1v-.2c5.3-1.5 8.9-6.2 9-11.7 0-6.8-5.9-12.3-13.1-12.3z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgTaxPrepIcon;
